import React, { useState } from "react";
import Modal from "../Modal";
import { TouchableOpacity, View, useWindowDimensions } from "react-native-web";
import ImageWithLoader from "../ImageWithLoader";
import colors from "../../assets/colors/colors";
import { LatoBold, LatoRegular } from "../Text";
import { MyInput } from "../Inputs";
import Buttons from "../Buttons";
import { GrClose } from "react-icons/gr";
import { regexChecker } from "../../tools/regexChecker";
import { api } from "../../utils/api";
import { asyncSetOpenSubscribe } from "../../tools/asyncStorages";
import { getDeviceType } from "../../tools/Interface";
import BNK from "../../assets/images/black-logo.svg";
import { LinksFromCDN } from "../../constants/cdnLinks";

function NewsletterModal({ setShow = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const [value, setValue] = useState("");
  const [loadingApply, setLoadingApply] = useState(false);
  const [error, setError] = useState("");
  const [showSecondContent, setShowSecondContent] = useState(false);

  const handleApply = async () => {
    try {
      setLoadingApply(true);

      if (!regexChecker("email", value)) {
        setError("Invalid email");
        return;
      }

      await api.post("/api/newsletters", { email: value });

      setShowSecondContent(true);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoadingApply(false);
    }
  };

  const handleCloseModal = async () => {
    await asyncSetOpenSubscribe();

    setShow(false);
  };

  return (
    <Modal width={isPhone ? 320 : 640} noBackground>
      <View
        style={{
          flexDirection: isPhone ? "column" : "row",
          backgroundColor: colors.whiteFF,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <ImageWithLoader
          source={LinksFromCDN.subscribeNewsletter}
          defaultStyle={{ width: 48, height: 269, borderRadius: 0 }}
          style={{ width: 320, height: 269, borderRadius: 0 }}
          containerStyle={{ width: 320, height: 269, borderRadius: 0 }}
          resizeMode="cover"
          woMask
        />
        <View
          style={{
            width: 320,
            height: 269,
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 24,
            paddingVertical: 10,
          }}
        >
          {showSecondContent ? (
            <ImageWithLoader
              source={BNK}
              defaultStyle={{ width: 48, height: 50, borderRadius: 0 }}
              style={{ width: 169, height: 50, borderRadius: 0 }}
              containerStyle={{ width: 169, height: 50, marginBottom: 12 }}
              resizeMode="contain"
              woMask
            />
          ) : (
            <LatoBold
              style={{
                fontSize: 20,
                lineHeight: 28,
                color: colors.grey33,
                marginBottom: 12,
              }}
            >
              Get IDR 500K Off
            </LatoBold>
          )}
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey6C,
              textAlign: "center",
              marginBottom: showSecondContent && !isPhone ? 0 : 32,
            }}
          >
            {showSecondContent
              ? "Welcome! Now, you'll be the first to receive updates on new arrivals and all the latest offers from us. Use SIGNUP500 for your voucher code to get your discount."
              : "Your first order when you join our mailing list"}
          </LatoRegular>
          {Boolean(!showSecondContent) && (
            <MyInput
              handleChange={(val) => {
                setValue(val);
              }}
              error={error}
              noLabel
              placeholder={"Email Address"}
              value={value}
              bordered
              color={value ? colors.grey33 : colors.greyBB}
              inline
              checkByName={false}
              customWidth={272}
            />
          )}
          {Boolean(!showSecondContent || (showSecondContent && isPhone)) && (
            <Buttons
              loading={loadingApply}
              disabled={!value || loadingApply}
              onPress={showSecondContent ? handleCloseModal : handleApply}
              isBlack
              label={showSecondContent ? "Done" : "Join mailing list"}
              borderRadius={2}
            />
          )}
        </View>
        <TouchableOpacity
          onPress={handleCloseModal}
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          <GrClose
            size={14}
            style={{
              borderRadius: "100%",
              backgroundColor: isPhone ? colors.whiteFF : "transparent",
              padding: isPhone ? 6 : 0,
            }}
          />
        </TouchableOpacity>
      </View>
    </Modal>
  );
}

export default NewsletterModal;
