import React, { useEffect } from "react";
import { View } from "react-native";
import RootNavigation from "./navigations/RootNavigation";
import { asyncGetCurrency, asyncSetCurrency } from "./tools/asyncStorages";

function App() {
  const handleCurrency = async () => {
    const currency = await asyncGetCurrency();

    if (!currency) {
      await asyncSetCurrency("IDR");
    }
  };
  
  const loadBarantum = async () => {
    (function(w, d, s, u,f) {
      w.BarantumLivechat = function(c) {w.BarantumLivechat.push(c);}; w.BarantumLivechat = []; w.BarantumLivechat.url = u;
      w.BarantumLivechat.serverUrl = 'https://belleandkate-livechat.barantum.com';
      var h = d.getElementsByTagName(s)[0], j = d.createElement(s);
      j.async = true; j.src = 'https://marketplace.barantum.com/assets/js/barantum-livechat/barantum-livechat.min.js?_=201903270000';
      h.parentNode.insertBefore(j, h);
    })(window, document, 'script', 'https://marketplace.barantum.com/assets/js/barantum-livechat/');
  };
  
  const addMidtransScript = async () => {
    let scriptNode=document.createElement('script');
    scriptNode.type='text/javascript';
    scriptNode.src='https://app.midtrans.com/snap/snap.js';
    scriptNode.setAttribute("data-client-key", "Mid-client-N4XuQ2dMIZ0afWRF");
    try{document.getElementsByTagName('head')[0].appendChild(scriptNode);} catch(e){console.error(e);}   
  };
  
  const addGMapScript = async () => {
    let scriptNode=document.createElement('script');
    scriptNode.type='text/javascript';
    scriptNode.src='https://maps.googleapis.com/maps/api/js?key=AIzaSyDNGKpExkTQvOaHmcl07wYvUNbWXjWc6HA';
    try{document.getElementsByTagName('head')[0].appendChild(scriptNode);} catch(e){console.error(e);}   
  };

  useEffect(() => {
    handleCurrency();
    setTimeout(async () => {
      addMidtransScript()
      // addGMapScript() //error on checkout
      await loadBarantum()
      document.getElementById('live_chat_floating_action_button').classList.remove('hide_live_chat')
    }, 5000);
  }, []);

  return (
    <View>
      <RootNavigation />
    </View>
  );
}

export default App;
