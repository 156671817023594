import { useEffect, useState } from "react";
import { ROOT_URL } from "../constants/api";
import InvoiceLogo from "../assets/images/invoice-logo.webp";
import { useDebounce } from 'use-debounce';
import { format } from "date-fns";

export default function OpsCreateInvoiceScreen() {
  const [displayHeaderAndFooter, setDisplayHeaderAndFooter] = useState(false)
  const [displayPrices, setDisplayPrices] = useState(true)
  const [displayCustomerName, setDisplayCustomerName] = useState(true)
  const [displayCustomerAddress, setDisplayCustomerAddress] = useState(true)
  const [displayCustomerPhone, setDisplayCustomerPhone] = useState(true)
  const [invoiceId, setInvoiceId] = useState(null)
  const [customerUserId, setCustomerUserId] = useState(undefined)
  const [customerName, setCustomerName] = useState("")
  const [customerAddress, setCustomerAddress] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [clientAdvisor, setClientAdvisor] = useState("")
  const [shippedVia, setShippedVia] = useState("")
  const [invoiceItemList, setInvoiceItemList] = useState([])
  const [pageTitle, setPageTitle] = useState("")
  const formattedInvoiceDate = invoiceDate
    ? format(new Date(invoiceDate), "d MMMM yy")
    : "-";

  const [savedProducts, setSavedProducts] = useState("")
  const [password, setPassword] = useState("")
  const [productSearchTerm, setProductSearchTerm] = useState("")
  const [productList, setProductList] = useState([])
  const [userSearchTerm, setUserSearchTerm] = useState("")
  const [userList, setUserList] = useState([])
  const [debouncedPassword] = useDebounce(password, 1000);
  const [debouncedUserSearchTerm] = useDebounce(userSearchTerm, 1000);
  const [debouncedProductSearchTerm] = useDebounce(productSearchTerm, 1000);
  const [debouncedPageTitle] = useDebounce(pageTitle, 1000);
  useEffect(() => {
    document.title = debouncedPageTitle
  }, [debouncedPageTitle])
  useEffect(() => {
    const handlePrintKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        if (savedProducts !== invoiceItemList.map(o => o.title).join("|")) {
          event.preventDefault();
          if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
          } else {
            event.stopPropagation();
          }
          alert("Mohon simpan sebelum melakukan print");
        }
      }
    };
  
    window.addEventListener("keydown", handlePrintKeyDown);
    return () => {
      window.removeEventListener("keydown", handlePrintKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedProducts, invoiceItemList.map(o => o.title).join("|")]);
  
  useEffect(() => {
    if(!debouncedPassword && debouncedUserSearchTerm){
      alert("Mohon isi password")
      return
    }
    if(!debouncedPassword){
      return
    }
    fetch(`${ROOT_URL}/api/user_data_for_invoice?password=${debouncedPassword}&q=${debouncedUserSearchTerm}`)
      .then(response => response.json())
      .then(resp => {
        if (resp.error) {
          alert(`Error: ${resp.error}`)
          return
        }
        const data = resp.data
        if (data) {
          setUserList(data)
        }
      })
      .catch(error => {
        alert(`Error: ${error}`)
        return
      });
  }, [debouncedPassword, debouncedUserSearchTerm]);
  useEffect(() => {
    if(!debouncedPassword && debouncedProductSearchTerm){
      alert("Mohon isi password")
      return
    }
    if(!debouncedPassword){
      return
    }
    fetch(`${ROOT_URL}/api/product_data_for_invoice?password=${debouncedPassword}&q=${debouncedProductSearchTerm}`)
      .then(response => response.json())
      .then(resp => {
        if (resp.error) {
          alert(`Error: ${resp.error}`)
          return
        }
        const data = resp.data
        if (data) {
          setProductList(data)
        }
      })
      .catch(error => {
        alert(`Error: ${error}`)
        return
      });
  }, [debouncedPassword, debouncedProductSearchTerm]);
  function reloadProduct(id){
    if(!debouncedPassword){
      alert("Mohon isi password")
      return
    }
    fetch(`${ROOT_URL}/api/product_data_for_invoice?password=${debouncedPassword}&id=${id}`)
      .then(response => response.json())
      .then(resp => {
        if (resp.error) {
          alert(`Error: ${resp.error}`)
          return
        }
        const data = resp.data
        if (data) {
          if(data.length === 0){
            alert(`Produk dengan id ini tidak ditemukan: ${id}`)
            return
          }
          const product = data[0]
          setInvoiceItemList(prevState => prevState.map(o => {
            if (o.id !== id) {
              return o
            } else {
              return {
                id: product.id,
                sku: product.sku,
                title: [product.condition?.name, product.name, product.stamp].filter(s => !!s).join(" "),
                description: product.comes_with,
                quantity: 1,
                currency: "IDR",
                amount: parseInt(product.selling_price),
                mainImageUrl: product.comes_with_image_url ? `${ROOT_URL}${product.comes_with_image_url}` : "",
                stampImageUrl: product.stamp_image_url ? `${ROOT_URL}${product.stamp_image_url}` : "",
              }
            }
          }))
        }
      })
      .catch(error => {
        alert(`Error: ${error}`)
        return
      });
  }
  function saveInvoice(){
    if(!debouncedPassword){
      alert("Mohon isi password")
      return
    }
    setSavedProducts(invoiceItemList.map(o => o.title).join("|"))
    let imageTransforms = []
    for (let i = 0; i < invoiceItemList.length; i++) {
      if(!invoiceItemList[i].amount){
        alert(`Mohon isi Amount (harga) untuk item: ${invoiceItemList[i].title}`)
        return
      }
      imageTransforms.push({
        img: {
          main: document.getElementById(`main-img-${i}`)?.style?.transform ?? '',
          stamp: document.getElementById(`stamp-img-${i}`)?.style?.transform ?? '',
        },
        div: {
          main: document.getElementById(`main-div-${i}`)?.style?.height ?? '',
          stamp: document.getElementById(`stamp-div-${i}`)?.style?.height ?? '',
        }
      })
    }
    const invoiceData = {
      id: invoiceId,
      displayHeaderAndFooter,
      customerUserId,
      customerName,
      customerAddress,
      customerPhone,
      invoiceDate,
      clientAdvisor,
      shippedVia,
      invoiceItemList,
      imageTransforms,
      pageTitle,
      password: debouncedPassword,
    };
    fetch(`${ROOT_URL}/api/save_invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(invoiceData),
    })
      .then(response => response.json())
      .then(resp => {
        if (resp.error) {
          alert(`Error: ${resp.error}`)
          return
        }
        const data = resp.data
        if (data) {
          setInvoiceId(data.invoiceId)
          alert(data.outputArr.join("\n"))
        }
      })
      .catch(error => {
        alert(`Error: ${error}`)
        return
      });
  }
  
  return (
    <div className="w-full flex justify-around items-center h-[100vh] overflow-scroll print:overflow-hidden">
      <div className="w-full print:hidden max-w-[30%] h-full">
        <h1 className="text-2xl font-bold text-center">Invoice</h1>
        <div className="flex flex-col mt-2">
          <input
            className="border border-gray-300"
            placeholder="Password"
            type="password"
            value={password}
            onChange={function(e){
              setPassword(e.target.value)
            }}
          />
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="display-header-and-footer"
                className="border border-gray-300 mr-2"
                checked={displayHeaderAndFooter}
                onChange={(e) => {
                  setDisplayHeaderAndFooter(prevState => !prevState)
                }}
              />
              <label htmlFor="display-header-and-footer" className="text-sm">
                Display Header & Footer
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="display-prices"
                className="border border-gray-300 mr-2"
                checked={displayPrices}
                onChange={(e) => {
                  setDisplayPrices(prevState => !prevState)
                }}
              />
              <label htmlFor="display-prices" className="text-sm">
                Display Prices
              </label>
            </div>
          </div>
          <br/>
          <div className="flex items-center justify-between">
            <a
              className={`bg-gray-100 hover:bg-gray-200 text-sm underline p-1`}
              href={ROOT_URL+`/admin/users/new`}
              target="_blank" rel="noreferrer"
            >
              Add Customer
            </a>
            <a
              className={customerUserId ? `bg-gray-100 hover:bg-gray-200 text-sm underline p-1` : "invisible"}
              href={customerUserId ? ROOT_URL+`/admin/users/${customerUserId}` : undefined}
              target="_blank" rel="noreferrer"
            >
              Edit Customer
            </a>
          </div>
          <input
            className="border border-gray-300"
            placeholder="Cari email atau nama customer..."
            value={userSearchTerm}
            onChange={function(e){
              setUserSearchTerm(e.target.value)
            }}
          />
          {
            userList.map(function(user){
              return (
                <div className="border p-2 cursor-pointer hover:bg-gray-100" onClick={function(e){
                  setCustomerUserId(user.id ?? undefined)
                  setCustomerName(user.name ?? "")
                  setCustomerAddress(user.address ?? "")
                  setCustomerPhone(`${user.phone_code ?? ""} ${user.phone ?? ""}`)
                  setUserSearchTerm("")
                  setUserList([])
                }}>
                  <p>{user.name} ({user.email})</p>
                </div>
              )
            })
          }
          {
            customerUserId &&
            <p className="font-bold mt-4 mb-1 flex justify-between items-center">
              User ID: {customerUserId}
              <button
                className={`bg-gray-100 hover:bg-gray-200 underline text-sm p-1`}
                onClick={() => {
                  setCustomerUserId(undefined)
                  setCustomerName("")
                  setCustomerAddress("")
                  setCustomerPhone("")
                }}                      
              >
                Clear User
              </button>
            </p>
          }
          <div className="flex gap-2">
            <input
              className="border border-gray-300 grow"
              placeholder="Customer Name"
              value={customerName}
              onChange={function(e){
                setCustomerName(e.target.value)
              }}
            />
            <div className="flex gap-1 items-center">
              <input
                type="checkbox"
                id="show-customer-name"
                name="show-customer-name"
                className="cursor-pointer"
                checked={displayCustomerName}
                onClick={function(e){
                  setDisplayCustomerName(prevState => !prevState)
                }}
              />
              <label for="show-customer-name" className="cursor-pointer text-sm">Show</label>
            </div>
          </div>
          <div className="flex gap-2">
            <textarea
              className="border border-gray-300 resize-y min-h-[76px] grow"
              placeholder="Customer Address"
              value={customerAddress}
              onChange={function(e){
                setCustomerAddress(e.target.value)
              }}
            />
            <div className="flex gap-1 items-center">
              <input
                type="checkbox"
                id="show-customer-address"
                name="show-customer-address"
                className="cursor-pointer"
                checked={displayCustomerAddress}
                onClick={function(e){
                  setDisplayCustomerAddress(prevState => !prevState)
                }}
              />
              <label for="show-customer-address" className="cursor-pointer text-sm">Show</label>
            </div>
          </div>
          <div className="flex gap-2">
            <input
              className="border border-gray-300 grow"
              placeholder="Customer Phone"
              value={customerPhone}
              onChange={function(e){
                setCustomerPhone(e.target.value)
              }}
            />
            <div className="flex gap-1 items-center">
              <input
                type="checkbox"
                id="show-customer-phone"
                name="show-customer-phone"
                className="cursor-pointer"
                checked={displayCustomerPhone}
                onClick={function(e){
                  setDisplayCustomerPhone(prevState => !prevState)
                }}
              />
              <label for="show-customer-phone" className="cursor-pointer text-sm">Show</label>
            </div>
          </div>
          <br/>
          <div className="flex items-center">
            <label htmlFor="invoice-date" className="text-sm mr-2">
              Invoice Date:
            </label>
            <input
              type="date"
              id="invoice-date"
              className="border border-gray-300 grow"
              placeholder="Invoice Date"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
            />
          </div>
          <input
            className="border border-gray-300"
            placeholder="Client Advisor"
            value={clientAdvisor}
            onChange={function(e){
              setClientAdvisor(e.target.value)
            }}
          />
          <input
            className="border border-gray-300"
            placeholder="Shipped Via"
            value={shippedVia}
            onChange={function(e){
              setShippedVia(e.target.value)
            }}
          />
          <br/>
          <input
            className="border border-gray-300"
            placeholder="Cari produk..."
            value={productSearchTerm}
            onChange={function(e){
              setProductSearchTerm(e.target.value)
            }}
          />
          {
            productList.map(function(product){
              return (
                <div className="border p-2 cursor-pointer hover:bg-gray-100" onClick={function(e){
                  setInvoiceItemList(prevState => [...prevState, {
                    id: product.id,
                    sku: product.sku,
                    title: [product.condition?.name, product.name, product.stamp].filter(s => !!s).join(" "),
                    description: product.comes_with,
                    quantity: 1,
                    currency: "IDR",
                    amount: parseInt(product.selling_price),
                    mainImageUrl: product.comes_with_image_url ? `${ROOT_URL}${product.comes_with_image_url}` : "",
                    stampImageUrl: product.stamp_image_url ? `${ROOT_URL}${product.stamp_image_url}` : "",
                  }])
                  setProductSearchTerm("")
                  setProductList([])
                }}>
                  <p>{product.name}</p>
                  <p>{product.sku} ({product.supplier_name})</p>
                </div>
              )
            })
          }
          <button
            className={`bg-gray-100 hover:bg-gray-200 underline text-sm p-1`}
            onClick={function(){
              setInvoiceItemList(prevState => [...prevState, {
                id: undefined,
                sku: "",
                title: "",
                description: "",
                quantity: 1,
                currency: "IDR",
                amount: 0,
                mainImageUrl: "",
                stampImageUrl: "",
              }])
            }}
          >
            Tambah Item
          </button>
          {
            invoiceItemList.map((item, i) => {
              return (
                <div key={i}>
                  <p className="font-bold mt-4 mb-1 flex justify-between">
                    <span>#{i+1} {item.sku}</span>
                    {
                      item.id &&
                      <>
                        <a
                          className={`bg-gray-100 hover:bg-gray-200 text-sm underline p-1`}
                          href={ROOT_URL+`/admin/products/${item.id}`}
                          target="_blank" rel="noreferrer"
                        >
                          Edit
                        </a>
                        <button
                          className={`bg-gray-100 hover:bg-gray-200 underline text-sm p-1`}
                          onClick={() => {
                            reloadProduct(item.id);
                          }}                      
                        >
                          Reload
                        </button>
                      </>
                    }
                    <button
                      className={`bg-gray-100 hover:bg-gray-200 underline text-sm p-1`}
                      onClick={() => {
                        setInvoiceItemList(prevState => prevState.filter((_, index) => index !== i));
                      }}                      
                    >
                      Hapus
                    </button>
                  </p>
                  <textarea
                    className="border border-gray-300 w-full"
                    placeholder="Title"
                    value={invoiceItemList[i].title}
                    onChange={function(e){
                      setInvoiceItemList(function(items){
                        items[i].title = e.target.value
                        return [...items]
                      })
                    }}
                  />
                  <input
                    className="border border-gray-300 w-full"
                    placeholder="Description"
                    value={invoiceItemList[i].description}
                    onChange={function(e){
                      setInvoiceItemList(function(items){
                        items[i].description = e.target.value
                        return [...items]
                      })
                    }}
                  />
                  <div className="flex w-full">
                    <p className="w-[10%]">Qty:</p>
                    <input
                      className="border border-gray-300 w-[10%]"
                      placeholder="Quantity"
                      type="number"
                      value={invoiceItemList[i].quantity}
                      onChange={function(e){
                        setInvoiceItemList(function(items){
                          items[i].quantity = e.target.value
                          return [...items]
                        })
                      }}
                    />
                    <p className="w-[20%] text-right px-2">Amount:</p>
                    <input
                      className="border border-gray-300 w-[20%]"
                      placeholder="Currency"
                      value={invoiceItemList[i].currency}
                      onChange={function(e){
                        setInvoiceItemList(function(items){
                          items[i].currency = e.target.value
                          return [...items]
                        })
                      }}
                    />
                    <input
                      className="border border-gray-300 w-[40%]"
                      placeholder="Amount"
                      type="number"
                      value={invoiceItemList[i].amount}
                      onChange={function(e){
                        setInvoiceItemList(function(items){
                          items[i].amount = e.target.value
                          return [...items]
                        })
                      }}
                    />
                  </div>
                  <input
                    className="border border-gray-300 w-full"
                    placeholder="Foto kelengkapan"
                    value={invoiceItemList[i].mainImageUrl}
                    onChange={function(e){
                      setInvoiceItemList(function(items){
                        items[i].mainImageUrl = e.target.value
                        return [...items]
                      })
                    }}
                  />
                  <div className="flex w-full">
                    <p className="px-2 text-center">Hr:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Foto Horizontal"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`main-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[0] = `translate(${e.target.value}px,`
                        document.getElementById(`main-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Vr:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Foto Vertical"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`main-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[1] = `${e.target.value}px)`
                        document.getElementById(`main-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Zm:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Foto Zoom"
                      type="number"
                      defaultValue={1}
                      step={0.1}
                      onChange={function(e){
                        const styleObj = document.getElementById(`main-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[2] = `scale(${e.target.value})`
                        document.getElementById(`main-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Rt:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Foto Rotate"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`main-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[3] = `rotate(${e.target.value}deg)`
                        document.getElementById(`main-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Tg:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Foto Tinggi"
                      type="number"
                      defaultValue={120}
                      onChange={function(e){
                        const styleObj = document.getElementById(`main-div-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        document.getElementById(`main-div-${i}`).style = `height: ${e.target.value}px; !important`
                      }}
                    />
                  </div>
                  <input
                    className="border border-gray-300 w-full"
                    placeholder="Foto stamp"
                    value={invoiceItemList[i].stampImageUrl}
                    onChange={function(e){
                      setInvoiceItemList(function(items){
                        items[i].stampImageUrl = e.target.value
                        return [...items]
                      })
                    }}
                  />
                  <div className="flex w-full">
                    <p className="px-2 text-center">Hr:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Stamp Horizontal"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`stamp-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[0] = `translate(${e.target.value}px,`
                        document.getElementById(`stamp-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Vr:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Stamp Vertical"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`stamp-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[1] = `${e.target.value}px)`
                        document.getElementById(`stamp-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Zm:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Stamp Zoom"
                      type="number"
                      defaultValue={1}
                      step={0.1}
                      onChange={function(e){
                        const styleObj = document.getElementById(`stamp-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[2] = `scale(${e.target.value})`
                        document.getElementById(`stamp-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Rt:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Stamp Rotate"
                      type="number"
                      defaultValue={0}
                      onChange={function(e){
                        const styleObj = document.getElementById(`stamp-img-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        const prevState = styleObj.transform || "translate(0px, 0px) scale(1) rotate(0deg)"
                        let prevStateArr = prevState.split(" ")
                        prevStateArr[3] = `rotate(${e.target.value}deg)`
                        document.getElementById(`stamp-img-${i}`).style.transform = prevStateArr.join(" ");
                      }}
                    />
                    <p className="px-2 text-center">Tg:</p>
                    <input
                      className="border border-gray-300 w-full"
                      placeholder="Stamp Tinggi"
                      type="number"
                      defaultValue={155}
                      onChange={function(e){
                        const styleObj = document.getElementById(`stamp-div-${i}`)?.style
                        if(!styleObj){
                          return
                        }
                        document.getElementById(`stamp-div-${i}`).style = `height: ${e.target.value}px; !important`
                      }}
                    />
                  </div>
                </div>
              )
            })
          }
          <br />
          <input
            className="border border-gray-300"
            placeholder="File Title"
            onChange={function(e){
              setPageTitle(e.target.value)
            }}
          />
          <button
            className={`bg-gray-100 hover:bg-gray-200 underline text-sm p-1`}
            onClick={() => {
              saveInvoice();
            }}                      
          >
            Simpan
          </button>
          {
            invoiceId
            &&
            <div className="flex mt-2">
              <a
                href="/ops/create-invoice"
                className={`bg-gray-100 hover:bg-gray-200 underline text-sm text-center p-1 grow mr-2`}
                target="_blank"
              >
                Tambah Invoice Baru
              </a>
              <p>Invoice #{invoiceId}</p>
            </div>
          }
          <ul className="mt-1 list-disc ml-6 text-sm">
            <li>Silahkan isi password dan juga login ke <a href="https://production.belleandkate.com/" className="underline" target="_blank" rel="noreferrer">production.belleandkate.com</a></li>
            <li>Jika user belum memiliki akun, Add Customer dan isi:</li>
            <ul className="mt-1 list-disc ml-6">
              <li>Email: cusbnk-[nama-unik-user]@yopmail.com</li>
              <li>Password: boleh asal tidak perlu diingat (harus diatas 6 karakter)</li>
              <li>Password confirmation: harus sama dengan password</li>
              <li>Fields yg perlu diisi: Name, Phone code + Phone, Complete address</li>
              <li>Klik Save User</li>
            </ul>
            <li>Jika perlu menambahkan foto dari Downloads:</li>
            <ul className="mt-1 list-disc ml-6">
              <li>Google lalu ikuti petunjuk: cara menampilkan extension file di windows 11</li>
              <li>Klik tombol Windows dan cari aplikasi Terminal</li>
              <li>Ketik: python -m http.server -d $HOME/Downloads</li>
              <li>Jika tidak bisa, coba: python.exe -m http.server -d $HOME/Downloads</li>
              <li>Jika setelah Enter keluar "Serving HTTP on ...", berarti sudah berhasil</li>
              <li>Jika belum berhasil, silahkan kontak IT</li>
              <li>Jika sudah berhasil, anda bisa menambahkan image dari Downloads dengan mengisi nama file beserta file extension (misal: .jpeg) di Foto kelengkapan</li>
            </ul>
            <li className="font-bold">Jangan lupa klik Simpan jika sudah selesai mengisi invoice.</li>
          </ul>
        </div>
      </div>
      
      <div className="flex flex-col min-w-[60%] mx-[37px]">
        <img src={InvoiceLogo} className={`h-[50px] w-[188px] mx-auto mb-[14px] ${displayHeaderAndFooter ? "" : "invisible"}`} alt="Belle and Kate Logo"/>
        <h2 className="text-[14px] font-bold mb-[4px]">INVOICE</h2>
        <div className="flex gap-[10px]">
          <div className="flex flex-col grow">
            <div className="flex grow">
              <p className={`text-[8px] font-bold min-w-[73px] ${!displayCustomerName && "invisible"}`}>SOLD TO:</p>
              <p className={`text-[8px] font-bold grow ml-2 ${!displayCustomerName && "invisible"}`}>{customerName}</p>
            </div>
            <div className="flex grow">
              <p className={`text-[8px] font-bold min-w-[73px] ${!displayCustomerAddress && "invisible"}`}>ADDRESS:</p>
              <p className={`text-[8px] font-bold grow ml-2 whitespace-pre-line ${!displayCustomerAddress && "invisible"}`}>{customerAddress}</p>
            </div>
            <div className="flex grow">
              <p className={`text-[8px] font-bold min-w-[73px] ${!displayCustomerPhone && "invisible"}`}>PHONE NUMBER:</p>
              <p className={`text-[8px] font-bold grow ml-2 ${!displayCustomerPhone && "invisible"}`}>{customerPhone}</p>
            </div>
          </div>
          <div className="flex flex-col grow min-w-[160px]">
            <div className="flex grow max-h-[12px]">
              <p className="text-[8px] font-bold min-w-[75px]">NO:</p>
              <p className="text-[8px] font-bold grow ml-2">-</p>
            </div>
            <div className="flex grow max-h-[12px]">
              <p className="text-[8px] font-bold min-w-[75px]">DATE:</p>
              <p className="text-[8px] font-bold grow ml-2">{formattedInvoiceDate}</p>
            </div>
            <div className="flex grow max-h-[12px]">
              <p className="text-[8px] font-bold min-w-[75px]">CLIENT ADVISOR:</p>
              <p className="text-[8px] font-bold grow ml-2">{clientAdvisor}</p>
            </div>
            <div className="flex grow max-h-[12px]">
              <p className="text-[8px] font-bold min-w-[75px]">SHIPPED VIA:</p>
              <p className="text-[8px] font-bold grow ml-2">{shippedVia}</p>
            </div>
          </div>
        </div>
        <table className="text-[7px] mt-[8px] mb-[16px]">
          <thead className="text-left border-b">
            <tr>
              <th className="pb-[7px]">DESCRIPTION</th>
              <th className={`pb-[7px] ${!displayPrices && 'invisible'}`}>QTY</th>
              <th className={`pb-[7px] ${!displayPrices && 'invisible'}`}>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {
              invoiceItemList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="pt-[7px]">
                      <div className="flex gap-[4px]">
                        <div>
                          <div id={`main-div-${i}`} className="w-[90px] max-h-[120px] overflow-hidden">
                            <img style={{scale: 1}} id={`main-img-${i}`} className="" alt="" src={processImageUrlForSrc(item.mainImageUrl) || "https://placehold.co/1512x2016"}/>
                          </div>
                          {item.stampImageUrl &&
                            <div id={`stamp-div-${i}`} className="w-[90px] h-[155px] overflow-hidden mt-[4px]">
                              <img id={`stamp-img-${i}`} className="" alt="" src={processImageUrlForSrc(item.stampImageUrl)}/>
                            </div>
                          }
                        </div>
                        <div>
                          <p className="font-bold mb-[4px]">
                            {item.title.replace(/\r/g, "").split("\n").map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))}
                          </p>
                          <div className="flex flex-col gap-[2px]">
                            {item.description.split(", ").map((line, index) => (
                              <div key={index} className="flex">
                                <svg width="10" height="10" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="20" y="20" width="160" height="160" fill="none" stroke="black" stroke-width="10"/>
                                  <polyline points="50,100 90,140 200,0" stroke="red" stroke-width="20" fill="none" stroke-linecap="square" stroke-linejoin="square"/>
                                </svg>
                                <span className="ml-[3px]">
                                  {line}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className={`pt-[7px] align-top ${!displayPrices && 'invisible'}`}>{item.quantity}</td>
                    <td className={`pt-[7px] flex justify-between ${!displayPrices && 'invisible'}`}><span>{item.currency}</span><span>{formatAmountForDisplay(item.amount)}</span></td>
                  </tr>
                )
              })
            }
            <tr>
              <td className="max-w-[300px] leading-[8px] py-[7px]">*Semua barang promo (harga khusus atau special price) yang sudah dibeli di BELLE&KATE Room adalah final. Kami tidak menerima klaim pengembalian dana atau pertukaran barang setelah barang dipesan. Terima kasih!</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tfoot className="font-bold border-t">
            <tr>
              <td></td>
              <td className={`pt-[7px] ${!displayPrices && 'invisible'}`}>Total</td>
              <td className={`pt-[7px] flex justify-between ${!displayPrices && 'invisible'}`}>{getTotalSpendUI(invoiceItemList)}</td>
            </tr>
          </tfoot>
        </table>
        <div className={`w-full text-center ${displayHeaderAndFooter ? "" : "invisible"}`}>
          <p className="text-[7px] font-semibold leading-[9px]">JL. JEND. SUDIRMAN KAV 52-53. LOT 22, RT 5 / RW 3, SENAYAN.</p>
          <p className="text-[7px] font-semibold leading-[9px]">KBY. BARU, KOTA JAKARTA SELATAN, DAERAH KHUSUS IBUKOTA JAKARTA 12190</p>
        </div>
      </div>
    </div>
  )
}

function getTotalSpendUI(invoiceItemList){
  const spend = getTotalSpend(invoiceItemList)
  return (<><span className="mx-[4px]">{spend.currency}</span><span>{formatAmountForDisplay(spend.amount)}</span></>)
}
function getTotalSpend(invoiceItemList){
  let total = 0
  let currency = ""
  for (let i = 0; i < invoiceItemList.length; i++) {
    const item = invoiceItemList[i];
    if (currency === "") {
      currency = item.currency
    }
    if (currency !== item.currency) {
      alert("Multi currency tidak diperbolehkan")
      total = 0
      break
    }
    total += item.quantity * item.amount
  }
  return {amount: total, currency}
}
function formatAmountForDisplay(numOrStr){
  const num = parseInt(numOrStr)
  if (!num){
    return "N/A"
  }
  return num.toLocaleString("en-US")
}
function processImageUrlForSrc(imageUrlOrName){
  if (!imageUrlOrName){
    return ""
  }
  if (imageUrlOrName.startsWith("http")){
    return imageUrlOrName
  }
  return "http://localhost:8000/" + encodeURIComponent(imageUrlOrName)
}