import React, { useEffect, useState } from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import Footer from "../components/Footer";
import { Link, useLocation } from "react-router-dom";
import { ROOT_URL } from "../constants/api";
import Buttons from "../components/Buttons";
import { openWhatsapp } from "../tools/tools";
import { contactDecider } from "../tools/decider";

export default function CatalogScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;
  const defaultTitle = "Catalogue"
  const [entryTitle, setEntryTitle] = useState(defaultTitle)
  const [entryImageUrl, setEntryImageUrl] = useState(undefined)
  const [children, setChildren] = useState(undefined)

  async function getData(id) {
    const hermesID = 1
    await fetch(`${ROOT_URL}/api/get_with_direct_children?id=${id || ""}`)
      .then(response => response.json())
      .then(resp => {
        if (resp.error) {
          alert(`Error: ${resp.error}`)
          setEntryTitle(null)
          setChildren([])
          return
        }
        const data = resp.data
        if (data) {
          setEntryTitle(data.parent?.title || defaultTitle)
          setEntryImageUrl(data.parent?.entry_image_url)
          setChildren(data.children)
        }
      })
      .catch(error => {
        alert(`Error: ${error}`)
        setEntryTitle(null)
        setChildren([])
        return
      }
    );
  }
  useEffect(()=>{
    getData(id)
  }, [id])

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            marginVertical: 48,
            marginHorizontal: 'auto',
            minHeight: 300,
            alignItems: "center",
            width: containerWidth,
          }}
        >
          <LatoBold
            style={{ fontSize: 36, marginBottom: 24 }}
          >
            {entryTitle}
          </LatoBold>
          {
            children === undefined
            &&
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
              }}
            >
              Loading..
            </LatoRegular>
          }
          {
            children?.length === 0
            &&
            <>
              {entryImageUrl && <img className="h-[200px] mb-8" src={ROOT_URL+entryImageUrl} alt={entryTitle}/>}
              <Buttons
                backgroundColor={colors.green5B}
                label={"Contact Us"}
                fontSize={14}
                lineHeight={20}
                noBorder
                width={168}
                height={32}
                color={colors.whiteFF}
                textStyle={{ fontFamily: "lato-regular" }}
                containerStyle={{ paddingHorizontal: 20, paddingVertical: 4 }}
                onPress={() => openWhatsapp(`Hi, I'd like to purchase the following products: ${entryTitle} ${window.location.href}`, contactDecider("Catalogue"))}
              />
            </>
          }
          <div class={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6`}>
            {children !== undefined && children.length > 0 && children.map(child => {
              return (
                <Link to={`/catalogue?id=${child.id}`} className="border" key={child.id}>
                  <div className="flex flex-col items-center p-4">
                    {child.entry_image_url && <img className="" src={ROOT_URL+child.entry_image_url} alt={child.title}/>}
                    <div className="flex-grow text-center">
                      <LatoRegular
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {child.title}
                      </LatoRegular>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
